@font-face {
  font-family: 'Kedit-font';
  src: url('fonts/Kedit-font.eot?rl9nd2');
  src: url('fonts/Kedit-font.eot?rl9nd2#iefix') format('embedded-opentype'),
    url('fonts/Kedit-font.ttf?rl9nd2') format('truetype'),
    url('fonts/Kedit-font.woff?rl9nd2') format('woff'),
    url('fonts/Kedit-font.svg?rl9nd2#Kedit-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="kz-e-"],
[class*=" kz-e-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Kedit-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kz-e-image2:before {
  content: "\e9c6";
}

.kz-e-add-l:before {
  content: "\ea4d";
}

.kz-e-muban-location:before {
  content: "\e9af";
}

.kz-e-muban-person:before {
  content: "\e9b2";
}

.kz-e-muban-blog:before {
  content: "\e9b3";
}

.kz-e-muban-shop:before {
  content: "\e9b4";
}

.kz-e-muban-flow:before {
  content: "\e9b5";
}

.kz-e-muban-bbs:before {
  content: "\e9b6";
}

.kz-e-muban-more:before {
  content: "\e9b7";
}

.kz-e-liebiao-more:before {
  content: "\e9b8";
}

.kz-e-liebiao-page:before {
  content: "\e9be";
}

.kz-e-liebiao-data:before {
  content: "\e9bf";
}

.kz-e-liebiao-user:before {
  content: "\e9c0";
}

.kz-e-liebiao-site:before {
  content: "\e9c1";
}

.kz-e-liebiao-article:before {
  content: "\e9c2";
}

.kz-e-liebiao-bbs:before {
  content: "\e9c8";
}

.kz-e-liebiao-service:before {
  content: "\e9c9";
}

.kz-e-liebiao-plug:before {
  content: "\e9d9";
}

.kz-e-liebiao-ticket:before {
  content: "\e9da";
}

.kz-e-liebiao-list:before {
  content: "\e9db";
}

.kz-e-liebiao-turntable:before {
  content: "\e9dc";
}

.kz-e-liebiao-shoplist:before {
  content: "\e9dd";
}

.kz-e-link-2:before {
  content: "\ea00";
}

.kz-e-sohu-news:before {
  content: "\ea4f";
}

.kz-e-service-info-list:before {
  content: "\ea3f";
}

.kz-e-kclub-Reverse-order:before {
  content: "\ea40";
}

.kz-e-kclub-Positive-order:before {
  content: "\ea41";
}

.kz-e-kclub-time:before {
  content: "\ea42";
}

.kz-e-service-list:before {
  content: "\ea43";
}

.kz-e-add-plugin:before {
  content: "\ea44";
}

.kz-e-survey-survey:before {
  content: "\ea45";
}

.kz-e-kkt:before {
  content: "\ea46";
}

.kz-e-scrape-scrape:before {
  content: "\ea47";
}

.kz-e-system_plugin-mobile_button:before {
  content: "\ea48";
}

.kz-e-kuaijian-kuaijian:before {
  content: "\ea49";
}

.kz-e-system_plugin-sms_button:before {
  content: "\ea4a";
}

.kz-e-goddenegg-goddenegg:before {
  content: "\ea4b";
}

.kz-e-ernie-ernie:before {
  content: "\ea32";
}

.kz-e-kuaivote-kuaivote-rank:before {
  content: "\ea33";
}

.kz-e-kuaivote-kuaivote-multi:before {
  content: "\ea34";
}

.kz-e-kuaivote-kuaivote:before {
  content: "\ea35";
}

.kz-e-system_plugin-weibo:before {
  content: "\ea36";
}

.kz-e-system_plugin-title:before {
  content: "\ea37";
}

.kz-e-system_plugin-weibo-min:before {
  content: "\ea38";
}

.kz-e-system_plugin-qrcode-min:before {
  content: "\ea39";
}

.kz-e-system_plugin-nav:before {
  content: "\ea3a";
}

.kz-e-18:before {
  content: "\ea3b";
}

.kz-e-underline-new:before {
  content: "\ea3c";
}

.kz-e-add-icon:before {
  content: "\ea3d";
}

.kz-e-info-page:before {
  content: "\ea3e";
}

.kz-e-more-more:before {
  content: "\ea25";
}

.kz-e-system_plugin-pic:before {
  content: "\ea26";
}

.kz-e-system_plugin-search:before {
  content: "\ea27";
}

.kz-e-changyan-changyan:before {
  content: "\ea28";
}

.kz-e-system_plugin-button:before {
  content: "\ea29";
}

.kz-e-system_plugin-pictxt:before {
  content: "\ea2a";
}

.kz-e-system_plugin-picgroup:before {
  content: "\ea2b";
}

.kz-e-system_plugin-focus:before {
  content: "\ea2c";
}

.kz-e-system_plugin-text:before {
  content: "\ea2d";
}

.kz-e-system_plugin-video:before {
  content: "\ea2e";
}

.kz-e-system_plugin-link:before {
  content: "\ea2f";
}

.kz-e-system_plugin-forumlist:before {
  content: "\ea30";
}

.kz-e-system_plugin-shopslist:before {
  content: "\ea31";
}

.kz-e-system_plugin-commodity-list:before {
  content: "\ea18";
}

.kz-e-system_plugin-topiclist:before {
  content: "\ea19";
}

.kz-e-form-form:before {
  content: "\ea1a";
}

.kz-e-html-html:before {
  content: "\ea1b";
}

.kz-e-js-js:before {
  content: "\ea1c";
}

.kz-e-system_plugin-divider:before {
  content: "\ea1d";
}

.kz-e-system_plugin-layout2coloums:before {
  content: "\ea1e";
}

.kz-e-system_plugin-space:before {
  content: "\ea1f";
}

.kz-e-system_plugin-layout_floor:before {
  content: "\ea20";
}

.kz-e-system_plugin-guide-follow:before {
  content: "\ea21";
}

.kz-e-system_plugin-map:before {
  content: "\ea22";
}

.kz-e-system_plugin-qrcode:before {
  content: "\ea23";
}

.kz-e-system_plugin-share:before {
  content: "\ea24";
}

.kz-e-integrals:before {
  content: "\ea0b";
}

.kz-e-regal:before {
  content: "\ea0c";
}

.kz-e-popularity:before {
  content: "\ea0d";
}

.kz-e-video-post:before {
  content: "\ea0e";
}

.kz-e-img-post:before {
  content: "\ea0f";
}

.kz-e-voice-post:before {
  content: "\ea10";
}

.kz-e-newsection:before {
  content: "\ea11";
}

.kz-e-scanqr:before {
  content: "\ea12";
}

.kz-e-newarticle:before {
  content: "\ea13";
}

.kz-e-setting:before {
  content: "\ea14";
}

.kz-e-pause:before {
  content: "\ea15";
}

.kz-e-play:before {
  content: "\ea16";
}

.kz-e-radio:before {
  content: "\ea17";
}

.kz-e-radio-checked:before {
  content: "\e9fe";
}

.kz-e-expand:before {
  content: "\ea0a";
}

.kz-e-add:before {
  content: "\ea09";
}

.kz-e-close:before {
  content: "\ea08";
}

.kz-e-star:before {
  content: "\ea07";
}

.kz-e-star-o:before {
  content: "\ea06";
}

.kz-e-home2:before {
  content: "\ea05";
}

.kz-e-remind:before {
  content: "\ea04";
}

.kz-e-envelope:before {
  content: "\ea03";
}

.kz-e-angle-up:before {
  content: "\ea02";
}

.kz-e-angle-down-2:before {
  content: "\ea01";
}

.kz-e-image:before {
  content: "\e9ff";
}

.kz-e-smile:before {
  content: "\e9fd";
}

.kz-e-comment:before {
  content: "\e9fc";
}

.kz-e-scan:before {
  content: "\e9fb";
}

.kz-e-warning:before {
  content: "\e9fa";
}

.kz-e-close-2:before {
  content: "\e9f9";
}

.kz-e-mini:before {
  content: "\e9f8";
}

.kz-e-more:before {
  content: "\e9f7";
}

.kz-e-search:before {
  content: "\e9f6";
}

.kz-e-angle-down-3:before {
  content: "\e9f5";
}

.kz-e-voice:before {
  content: "\e929";
}

.kz-e-personal:before {
  content: "\e9f4";
}

.kz-e-share:before {
  content: "\e9f0";
}

.kz-e-watch:before {
  content: "\e9e2";
}

.kz-e-copy:before {
  content: "\e9e6";
}

.kz-e-list2:before {
  content: "\e9e7";
}

.kz-e-changegroup:before {
  content: "\e9e9";
}

.kz-e-rename:before {
  content: "\e9eb";
}

.kz-e-lock-o:before {
  content: "\e9ec";
}

.kz-e-lock:before {
  content: "\e9ed";
}

.kz-e-align-l:before {
  content: "\e900";
}

.kz-e-align-m:before {
  content: "\e9ef";
}

.kz-e-align-r:before {
  content: "\e9f2";
}

.kz-e-check:before {
  content: "\e9f1";
}

.kz-e-bold:before {
  content: "\e9ee";
}

.kz-e-lean:before {
  content: "\e9e0";
}

.kz-e-underline:before {
  content: "\e9df";
}

.kz-e-link:before {
  content: "\e9d6";
}

.kz-e-plus:before {
  content: "\e9d2";
}

.kz-e-angle-down:before {
  content: "\e9d1";
}

.kz-e-angle-right:before {
  content: "\e9d0";
}

.kz-e-angle-left:before {
  content: "\e9cd";
}

.kz-e-phone:before {
  content: "\ea75";
}

.kz-e-QQ:before {
  content: "\ea76";
}

.kz-e-qq:before {
  content: "\ea66";
}

.kz-e-wisdom-tooth:before {
  content: "\ea67";
}

.kz-e-form:before {
  content: "\ea68";
}

.kz-e-shop-list .path1:before {
  content: "\ea69";
  color: rgb(26, 24, 24);
}

.kz-e-shop-list .path2:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(26, 24, 24);
}

.kz-e-shop-list .path3:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.kz-e-shop-list .path4:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.kz-e-shop-list .path5:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.kz-e-shop-list .path6:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.kz-e-questionnaire .path1:before {
  content: "\ea6f";
  color: rgb(0, 0, 0);
}

.kz-e-questionnaire .path2:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(0, 2, 0);
}

.kz-e-questionnaire .path3:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(0, 2, 0);
}

.kz-e-questionnaire .path4:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(0, 2, 0);
}

.kz-e-questionnaire .path5:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(0, 2, 0);
}

.kz-e-service-information-list:before {
  content: "\ea5e";
}

.kz-e-title:before {
  content: "\ea58";
}

.kz-e-big-wheel:before {
  content: "\ea59";
}

.kz-e-navigation:before {
  content: "\ea5a";
}

.kz-e-separate:before {
  content: "\ea5b";
}

.kz-e-leaving-space:before {
  content: "\ea5c";
}

.kz-e-pictures2:before {
  content: "\ea5d";
}

.kz-e-keyboard:before {
  content: "\ea54";
}

.kz-e-wechat:before {
  content: "\e9c5";
}

.kz-e-bianjiqi-T:before {
  content: "\e938";
}

.kz-e-bianjiqi-picture:before {
  content: "\e939";
}

.kz-e-bianjiqi-pictures:before {
  content: "\e93a";
}

.kz-e-bianjiqi-circle:before {
  content: "\e94c";
}

.kz-e-bianjiqi-link:before {
  content: "\e951";
}

.kz-e-bianjiqi-search:before {
  content: "\e954";
}

.kz-e-bianjiqi-vedio:before {
  content: "\e95b";
}

.kz-e-bianjiqi-chart:before {
  content: "\e95c";
}

.kz-e-bianjiqi-html:before {
  content: "\e95d";
}

.kz-e-bianjiqi-js:before {
  content: "\e95e";
}

.kz-e-bianjiqi-spacing:before {
  content: "\e95f";
}

.kz-e-bianjiqi-cut:before {
  content: "\e960";
}

.kz-e-bianjiqi-double:before {
  content: "\e961";
}

.kz-e-bianjiqi-yinying:before {
  content: "\e962";
}

.kz-e-bianjiqi-position:before {
  content: "\e963";
}

.kz-e-bianjiqi-saoma:before {
  content: "\e964";
}

.kz-e-bianjiqi-share:before {
  content: "\e965";
}

.kz-e-bianjiqi-wechat:before {
  content: "\e966";
}

.kz-e-bianjiqi-chang:before {
  content: "\e967";
}

.kz-e-bianjiqi-heart:before {
  content: "\e968";
}

.kz-e-bianjiqi-chatlist:before {
  content: "\e969";
}

.kz-e-bianjiqi-chat:before {
  content: "\e96a";
}

.kz-e-bianjiqi-shop:before {
  content: "\e96b";
}

.kz-e-bianjiqi-shoplist:before {
  content: "\e96c";
}

.kz-e-bianjiqi-page:before {
  content: "\e96d";
}

.kz-e-bianjiqi-ticket:before {
  content: "\e96e";
}

.kz-e-bianjiqi-link-2:before {
  content: "\e96f";
}

.kz-e-bianjiqi-paper:before {
  content: "\e970";
}

.kz-e-bianjiqi-check-1:before {
  content: "\e971";
}

.kz-e-bianjiqi-check-2:before {
  content: "\e972";
}

.kz-e-bianjiqi-article:before {
  content: "\e973";
}

.kz-e-bianjiqi-check-3:before {
  content: "\e974";
}

.kz-e-bianjiqi-more:before {
  content: "\e975";
}

.kz-e-bianjiqi-add:before {
  content: "\e976";
}

.kz-e-bianjiqi-:before {
  content: "\e980";
}

.kz-e-bianjiqi-ticket-2:before {
  content: "\e987";
}

.kz-e-bianjiqi-ear:before {
  content: "\e9aa";
}

.kz-e-bianjiqi-weibo:before {
  content: "\e9ab";
}

.kz-e-bianjiqi-up:before {
  content: "\e9ac";
}

.kz-e-add-big:before {
  content: "\e9ad";
}

.kz-e-picture-big:before {
  content: "\e9ae";
}

.kz-e-download:before {
  content: "\e9e4";
}

.kz-e-time-01:before {
  content: "\ea5f";
}

.kz-e-enterprise:before {
  content: "\e9f3";
}

.kz-e-import:before {
  content: "\ea50";
}

.kz-e-QR-code:before {
  content: "\ea51";
}

.kz-e-reading-number:before {
  content: "\e9c7";
}

.kz-e-text:before {
  content: "\e9ce";
}

.kz-e-image-text:before {
  content: "\e9d4";
}

.kz-e-image3:before {
  content: "\e9e8";
}

.kz-e-arrow-up:before {
  content: "\e911";
}

.kz-e-arrow-down2:before {
  content: "\e9b1";
}

.kz-e-*20-02:before {
  content: "\e9c4";
}

.kz-e-question-new:before {
  content: "\ea53";
}

.kz-e-tips:before {
  content: "\e912";
}

.kz-e-check--new-:before {
  content: "\e913";
}

.kz-e-add-new--1:before {
  content: "\e914";
}

.kz-e-question-new-1:before {
  content: "\e915";
}

.kz-e-check--new--1:before {
  content: "\e916";
}

.kz-e-user:before {
  content: "\e917";
}

.kz-e-bold-new-:before {
  content: "\e918";
}

.kz-e-italic:before {
  content: "\e919";
}

.kz-e-underline-new-1:before {
  content: "\e91a";
}

.kz-e-type-new:before {
  content: "\e91b";
}

.kz-e-type-new-1:before {
  content: "\e91c";
}

.kz-e-type-new-2:before {
  content: "\e91d";
}

.kz-e-wenzhang-up:before {
  content: "\e91e";
}

.kz-e-wenzhang-down:before {
  content: "\e91f";
}

.kz-e-wenzhang-suojin:before {
  content: "\e920";
}

.kz-e-wenzhang-spacing:before {
  content: "\e921";
}

.kz-e-wenzhang-right:before {
  content: "\e922";
}

.kz-e-wenzhang-left:before {
  content: "\e923";
}

.kz-e-wenzhang-center:before {
  content: "\e924";
}

.kz-e-wenzhang-brush:before {
  content: "\e925";
}

.kz-e-wenzhang-eraser:before {
  content: "\e926";
}

.kz-e-wenzhang-chart:before {
  content: "\e927";
}

.kz-e-wenzhang-I:before {
  content: "\e928";
}

.kz-e-wenzhang-I-2:before {
  content: "\e92a";
}

.kz-e-wenzhang-list:before {
  content: "\e92b";
}

.kz-e-wenzhang-list-2:before {
  content: "\e92c";
}

.kz-e-pictures:before {
  content: "\e92d";
}

.kz-e-wenzhang-pictures-2:before {
  content: "\e92e";
}

.kz-e-wenzhang-link:before {
  content: "\e92f";
}

.kz-e-wenzhang-link2:before {
  content: "\e930";
}

.kz-e-wenzhang-smile:before {
  content: "\e931";
}

.kz-e-wenzhang-vedio:before {
  content: "\e932";
}

.kz-e-wenzhang-shop:before {
  content: "\e933";
}

.kz-e-wenzhang-music:before {
  content: "\e934";
}

.kz-e-wenzhang-return:before {
  content: "\e935";
}

.kz-e-wenzhang-return2:before {
  content: "\e936";
}

.kz-e-wenzhang-html:before {
  content: "\e937";
}

.kz-e-user-not-logged:before {
  content: "\e9d8";
}

.kz-e-add-m:before {
  content: "\e9c3";
}

.kz-e-set-border-radius:before {
  content: "\e979";
}

.kz-e-set-padding:before {
  content: "\e977";
}

.kz-e-set-margin:before {
  content: "\e978";
}

.kz-e-lock-radius:before {
  content: "\e955";
}

.kz-e-unlock-radius:before {
  content: "\e956";
}

.kz-e-check2:before {
  content: "\ea74";
}

.kz-e-warn2:before {
  content: "\e902";
}

.kz-e-up:before {
  content: "\e903";
}

.kz-e-play-2:before {
  content: "\e904";
}

.kz-e-delete:before {
  content: "\e905";
}

.kz-e-down-3:before {
  content: "\e906";
}

.kz-e-edit-3:before {
  content: "\e907";
}

.kz-e-close-3:before {
  content: "\e908";
}

.kz-e-close-4:before {
  content: "\e909";
}

.kz-e-next-3:before {
  content: "\e90a";
}

.kz-e-time-3:before {
  content: "\e90b";
}

.kz-e-down:before {
  content: "\e90c";
}

.kz-e-rili:before {
  content: "\e90d";
}

.kz-e-download-3:before {
  content: "\e90e";
}

.kz-e-*14-14:before {
  content: "\e90f";
}

.kz-e-arrow-down:before {
  content: "\e9ca";
}

.kz-e-calendar:before {
  content: "\e9cb";
}

.kz-e-close2:before {
  content: "\e9cc";
}

.kz-e-download-solid:before {
  content: "\e9de";
}

.kz-e-edit:before {
  content: "\e9cf";
}

.kz-e-error-solid:before {
  content: "\e9e1";
}

.kz-e-foward-right:before {
  content: "\e9e3";
}

.kz-e-time:before {
  content: "\e9d3";
}

.kz-e-upload-solid:before {
  content: "\e9d5";
}

.kz-e-add-new2:before {
  content: "\e97a";
}

.kz-e-close-new:before {
  content: "\e97b";
}

.kz-e-help:before {
  content: "\e97c";
}

.kz-e-component:before {
  content: "\e93b";
}

.kz-e-pages:before {
  content: "\e93c";
}

.kz-e-theme:before {
  content: "\e93d";
}

.kz-e-redo:before {
  content: "\e93e";
}

.kz-e-undo:before {
  content: "\e93f";
}

.kz-e-copy-new:before {
  content: "\e940";
}

.kz-e-paste:before {
  content: "\e941";
}

.kz-e-pages-component:before {
  content: "\e942";
}

.kz-e-setting-new:before {
  content: "\e943";
}

.kz-e-share-new:before {
  content: "\e944";
}

.kz-e-ruler:before {
  content: "\e945";
}

.kz-e-refresh:before {
  content: "\e946";
}

.kz-e-save:before {
  content: "\e947";
}

.kz-e-preview:before {
  content: "\e948";
}

.kz-e-update-pages:before {
  content: "\e949";
}

.kz-e-create-group:before {
  content: "\e957";
}

.kz-e-create-page:before {
  content: "\e958";
}

.kz-e-search-new:before {
  content: "\e959";
}

.kz-e-page:before {
  content: "\ea60";
}

.kz-e-copy2:before {
  content: "\ea61";
}

.kz-e-delete2:before {
  content: "\ea62";
}

.kz-e-file:before {
  content: "\ea63";
}

.kz-e-close-eye:before {
  content: "\ea64";
}

.kz-e-open-eye:before {
  content: "\ea65";
}

.kz-e-sort2:before {
  content: "\ea55";
}

.kz-e-list:before {
  content: "\ea56";
}

.kz-e-sort22:before {
  content: "\ea57";
}

.kz-e-loading-button:before {
  content: "\e910";
}

.kz-e-nav-pull-down:before {
  content: "\ea52";
}

.kz-e-collection:before {
  content: "\e9ea";
}

.kz-e-pull-down:before {
  content: "\ea4c";
}

.kz-e-forward:before {
  content: "\ea4e";
}

.kz-e-home:before {
  content: "\e901";
}

.kz-e-que:before {
  content: "\e9e5";
}

.kz-e-success-solid:before {
  content: "\e9d7";
}

.kz-e-unwatch:before {
  content: "\e9b9";
}

.kz-e-upgrade:before {
  content: "\e9ba";
}

.kz-e-liebiao:before {
  content: "\e9bb";
}

.kz-e-shaixuan:before {
  content: "\e9bc";
}

.kz-e-kz-99-plugin:before {
  content: "\e9bd";
}

.kz-e-system_plugin-layout2coloums-min:before {
  content: "\e9b0";
}

.kz-e-page_plugin-down:before {
  content: "\e9a6";
}

.kz-e-page_plugin-up:before {
  content: "\e9a7";
}

.kz-e-page_plugin-right:before {
  content: "\e9a8";
}

.kz-e-page_plugin-left:before {
  content: "\e9a9";
}

.kz-e-system_plugin-button-min:before {
  content: "\e986";
}

.kz-e-system_plugin-title-min:before {
  content: "\e991";
}

.kz-e-html-html-min:before {
  content: "\e992";
}

.kz-e-js-js-min:before {
  content: "\e994";
}

.kz-e-form-form-min:before {
  content: "\e9a5";
}

.kz-e-copy-page:before {
  content: "\e94a";
}

.kz-e-del-new:before {
  content: "\e97e";
}

.kz-e-system_plugin-link-min:before {
  content: "\e97d";
}

.kz-e-drop-down:before {
  content: "\e97f";
}

.kz-e-drop-up:before {
  content: "\e99c";
}

.kz-e-drop-right:before {
  content: "\e99f";
}

.kz-e-drop-left:before {
  content: "\e9a0";
}

.kz-e-system_plugin-search-min:before {
  content: "\e981";
}

.kz-e-changyan-changyan-min:before {
  content: "\e982";
}

.kz-e-system_plugin-pictxt-min:before {
  content: "\e983";
}

.kz-e-system_plugin-picgroup-min:before {
  content: "\e984";
}

.kz-e-system_plugin-pic-min:before {
  content: "\e985";
}

.kz-e-system_plugin-nav-min:before {
  content: "\e988";
}

.kz-e-system_plugin-focus-min:before {
  content: "\e989";
}

.kz-e-system_plugin-text-min:before {
  content: "\e98a";
}

.kz-e-edit-new:before {
  content: "\e98b";
}

.kz-e-system_plugin-guide-follow-min:before {
  content: "\e98c";
}

.kz-e-system_plugin-forumlist-min:before {
  content: "\e98d";
}

.kz-e-system_plugin-shopslist-min:before {
  content: "\e98e";
}

.kz-e-system_plugin-commodity-list-min:before {
  content: "\e98f";
}

.kz-e-system_plugin-topiclist-min:before {
  content: "\e990";
}

.kz-e-system_plugin-video-min:before {
  content: "\e993";
}

.kz-e-system_plugin-divider-min:before {
  content: "\e995";
}

.kz-e-system_plugin-layout_floor-min:before {
  content: "\e996";
}

.kz-e-system_plugin-space-min:before {
  content: "\e997";
}

.kz-e-system_plugin-map-min:before {
  content: "\e998";
}

.kz-e-system_plugin-share-min:before {
  content: "\e999";
}

.kz-e-info-left:before {
  content: "\e99a";
}

.kz-e-info-right:before {
  content: "\e99b";
}

.kz-e-more-new:before {
  content: "\e9a1";
}

.kz-e-pagination-up:before {
  content: "\e9a2";
}

.kz-e-pagination-down:before {
  content: "\e9a3";
}

.kz-e-pagination-previous:before {
  content: "\e99d";
}

.kz-e-pagination-next:before {
  content: "\e99e";
}

.kz-e-16:before {
  content: "\e9a4";
}

.kz-e-lock-ratio:before {
  content: "\e94b";
}

.kz-e-left-justified:before {
  content: "\e94d";
}

.kz-e-center-justified:before {
  content: "\e94e";
}

.kz-e-right-justified:before {
  content: "\e94f";
}

.kz-e-bold-new:before {
  content: "\e950";
}

.kz-e-lean-new:before {
  content: "\e952";
}

.kz-e-add-new:before {
  content: "\e953";
}

.kz-e-info-group:before {
  content: "\e95a";
}