/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

/* stylelint-disable at-rule-no-unknown */

html,
body {
  width: 100%;
  height: 100%;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.186667rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
}

[tabindex='-1']:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1DA57A;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

a:focus {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

a:hover {
  color: #3db389;
}

a:active {
  color: #118060;
}

a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}

a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}

pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -0.026667rem;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
  background-color: #feffe6;
}

::selection {
  color: #fff;
  background: #1DA57A;
}

.clearfix {
  zoom: 1;
}

.clearfix::before,
.clearfix::after {
  content: '';
  display: table;
}

.clearfix::after {
  clear: both;
}

.anticon {
  display: inline-block;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.anticon > * {
  line-height: 1;
}

.anticon svg {
  display: inline-block;
}

.anticon::before {
  display: none;
}

.anticon .anticon-icon {
  display: block;
}

.anticon[tabindex] {
  cursor: pointer;
}

.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.fade-enter,
.fade-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.fade-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  -webkit-animation-name: antFadeIn;
  animation-name: antFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
  animation-name: antFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.fade-enter,
.fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.fade-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.move-up-enter,
.move-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
  animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
  animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.move-up-enter,
.move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.move-down-enter,
.move-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
  animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
  animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.move-down-enter,
.move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.move-left-enter,
.move-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
  animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
  animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.move-left-enter,
.move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.move-right-enter,
.move-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
  animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
  animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.move-right-enter,
.move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}

[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: -0.013333rem;
  right: -0.013333rem;
  bottom: -0.013333rem;
  left: -0.013333rem;
  display: block;
  border: 0 solid #1DA57A;
  border-radius: inherit;
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  pointer-events: none;
  content: '';
}

@-webkit-keyframes waveEffect {
  100% {
    top: -0.08rem;
    right: -0.08rem;
    bottom: -0.08rem;
    left: -0.08rem;
    border-width: 0.08rem;
  }
}

@keyframes waveEffect {
  100% {
    top: -0.08rem;
    right: -0.08rem;
    bottom: -0.08rem;
    left: -0.08rem;
    border-width: 0.08rem;
  }
}

@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

.slide-up-enter,
.slide-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.slide-down-enter,
.slide-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.slide-left-enter,
.slide-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
  animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
  animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.slide-right-enter,
.slide-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
  animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
  animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
}

@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
}

.swing-enter,
.swing-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.swing-enter.swing-enter-active,
.swing-appear.swing-appear-active {
  -webkit-animation-name: antSwingIn;
  animation-name: antSwingIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@-webkit-keyframes antSwingIn {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  20% {
    -webkit-transform: translateX(-0.133333rem);
    transform: translateX(-0.133333rem);
  }

  40% {
    -webkit-transform: translateX(0.133333rem);
    transform: translateX(0.133333rem);
  }

  60% {
    -webkit-transform: translateX(-0.066667rem);
    transform: translateX(-0.066667rem);
  }

  80% {
    -webkit-transform: translateX(0.066667rem);
    transform: translateX(0.066667rem);
  }
}

@keyframes antSwingIn {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  20% {
    -webkit-transform: translateX(-0.133333rem);
    transform: translateX(-0.133333rem);
  }

  40% {
    -webkit-transform: translateX(0.133333rem);
    transform: translateX(0.133333rem);
  }

  60% {
    -webkit-transform: translateX(-0.066667rem);
    transform: translateX(-0.066667rem);
  }

  80% {
    -webkit-transform: translateX(0.066667rem);
    transform: translateX(0.066667rem);
  }
}

.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
  animation-name: antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
  animation-name: antZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-big-enter,
.zoom-big-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-big-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-big-enter,
.zoom-big-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-up-enter,
.zoom-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
  animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
  animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-up-enter,
.zoom-up-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-down-enter,
.zoom-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
  animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
  animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-down-enter,
.zoom-down-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-left-enter,
.zoom-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
  animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
  animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-left-enter,
.zoom-left-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-right-enter,
.zoom-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
  animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
  animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-right-enter,
.zoom-right-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
}

@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
}

@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
}

@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}

@keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}

@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
}

@keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
}

@keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
}

@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }
}

@keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}

@keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}

@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }
}

@keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

@keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
}

@keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
}

.ant-motion-collapse {
  overflow: hidden;
}

.ant-motion-collapse-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.186667rem;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
}

.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ant-carousel .slick-list:focus {
  outline: none;
}

.ant-carousel .slick-list.dragging {
  cursor: pointer;
}

.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}

.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}

.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}

.ant-carousel .slick-track::after {
  clear: both;
}

.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}

.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 0.013333rem;
}

[dir='rtl'] .ant-carousel .slick-slide {
  float: right;
}

.ant-carousel .slick-slide img {
  display: block;
}

.ant-carousel .slick-slide.slick-loading img {
  display: none;
}

.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}

.ant-carousel .slick-initialized .slick-slide {
  display: block;
}

.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}

.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 0.013333rem solid transparent;
}

.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 0.266667rem;
  height: 0.266667rem;
  margin-top: -0.133333rem;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}

.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}

.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}

.ant-carousel .slick-prev {
  left: -0.333333rem;
}

.ant-carousel .slick-prev::before {
  content: '\2190';
}

.ant-carousel .slick-next {
  right: -0.333333rem;
}

.ant-carousel .slick-next::before {
  content: '\2192';
}

.ant-carousel .slick-dots {
  position: absolute;
  bottom: 0.16rem;
  display: block;
  width: 100%;
  height: 0.04rem;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}

.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 0.026667rem;
  padding: 0;
  text-align: center;
  vertical-align: top;
}

.ant-carousel .slick-dots li button {
  display: block;
  width: 0.213333rem;
  height: 0.04rem;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 0.013333rem;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}

.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}

.ant-carousel .slick-dots li.slick-active button {
  width: 0.32rem;
  background: #fff;
  opacity: 1;
}

.ant-carousel .slick-dots li.slick-active button:hover,
.ant-carousel .slick-dots li.slick-active button:focus {
  opacity: 1;
}

.ant-carousel-vertical .slick-dots {
  top: 50%;
  right: 0.16rem;
  bottom: auto;
  width: 0.04rem;
  height: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-carousel-vertical .slick-dots li {
  margin: 0 0.026667rem;
  vertical-align: baseline;
}

.ant-carousel-vertical .slick-dots li button {
  width: 0.04rem;
  height: 0.213333rem;
}

.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 0.04rem;
  height: 0.32rem;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.186667rem;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 0.213333rem;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}

.ant-message-notice {
  padding: 0.106667rem;
  text-align: center;
}

.ant-message-notice:first-child {
  margin-top: -0.106667rem;
}

.ant-message-notice-content {
  display: inline-block;
  padding: 0.133333rem 0.213333rem;
  background: #fff;
  border-radius: 0.053333rem;
  box-shadow: 0 0.053333rem 0.16rem rgba(0, 0, 0, 0.15);
  pointer-events: all;
}

.ant-message-success .anticon {
  color: #52c41a;
}

.ant-message-error .anticon {
  color: #f5222d;
}

.ant-message-warning .anticon {
  color: #faad14;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}

.ant-message .anticon {
  position: relative;
  top: 0.013333rem;
  margin-right: 0.106667rem;
  font-size: 0.213333rem;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
  -webkit-animation-name: MessageMoveOut;
  animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 2rem;
    padding: 0.106667rem;
    opacity: 1;
  }

  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 2rem;
    padding: 0.106667rem;
    opacity: 1;
  }

  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@font-face {
  font-family: 'Kedit-font';
  src: url(//cdn.kuaizhan.com/idk-homepage/static/media/Kedit-font.cbd108a8.eot);
  src: url(//cdn.kuaizhan.com/idk-homepage/static/media/Kedit-font.cbd108a8.eot#iefix) format('embedded-opentype'),
    url(//cdn.kuaizhan.com/idk-homepage/static/media/Kedit-font.a0487fec.ttf) format('truetype'),
    url(//cdn.kuaizhan.com/idk-homepage/static/media/Kedit-font.af75bafc.woff) format('woff'),
    url(//cdn.kuaizhan.com/idk-homepage/static/media/Kedit-font.ad38dc0a.svg#Kedit-font) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="kz-e-"],
[class*=" kz-e-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Kedit-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kz-e-image2:before {
  content: "\E9C6";
}

.kz-e-add-l:before {
  content: "\EA4D";
}

.kz-e-muban-location:before {
  content: "\E9AF";
}

.kz-e-muban-person:before {
  content: "\E9B2";
}

.kz-e-muban-blog:before {
  content: "\E9B3";
}

.kz-e-muban-shop:before {
  content: "\E9B4";
}

.kz-e-muban-flow:before {
  content: "\E9B5";
}

.kz-e-muban-bbs:before {
  content: "\E9B6";
}

.kz-e-muban-more:before {
  content: "\E9B7";
}

.kz-e-liebiao-more:before {
  content: "\E9B8";
}

.kz-e-liebiao-page:before {
  content: "\E9BE";
}

.kz-e-liebiao-data:before {
  content: "\E9BF";
}

.kz-e-liebiao-user:before {
  content: "\E9C0";
}

.kz-e-liebiao-site:before {
  content: "\E9C1";
}

.kz-e-liebiao-article:before {
  content: "\E9C2";
}

.kz-e-liebiao-bbs:before {
  content: "\E9C8";
}

.kz-e-liebiao-service:before {
  content: "\E9C9";
}

.kz-e-liebiao-plug:before {
  content: "\E9D9";
}

.kz-e-liebiao-ticket:before {
  content: "\E9DA";
}

.kz-e-liebiao-list:before {
  content: "\E9DB";
}

.kz-e-liebiao-turntable:before {
  content: "\E9DC";
}

.kz-e-liebiao-shoplist:before {
  content: "\E9DD";
}

.kz-e-link-2:before {
  content: "\EA00";
}

.kz-e-sohu-news:before {
  content: "\EA4F";
}

.kz-e-service-info-list:before {
  content: "\EA3F";
}

.kz-e-kclub-Reverse-order:before {
  content: "\EA40";
}

.kz-e-kclub-Positive-order:before {
  content: "\EA41";
}

.kz-e-kclub-time:before {
  content: "\EA42";
}

.kz-e-service-list:before {
  content: "\EA43";
}

.kz-e-add-plugin:before {
  content: "\EA44";
}

.kz-e-survey-survey:before {
  content: "\EA45";
}

.kz-e-kkt:before {
  content: "\EA46";
}

.kz-e-scrape-scrape:before {
  content: "\EA47";
}

.kz-e-system_plugin-mobile_button:before {
  content: "\EA48";
}

.kz-e-kuaijian-kuaijian:before {
  content: "\EA49";
}

.kz-e-system_plugin-sms_button:before {
  content: "\EA4A";
}

.kz-e-goddenegg-goddenegg:before {
  content: "\EA4B";
}

.kz-e-ernie-ernie:before {
  content: "\EA32";
}

.kz-e-kuaivote-kuaivote-rank:before {
  content: "\EA33";
}

.kz-e-kuaivote-kuaivote-multi:before {
  content: "\EA34";
}

.kz-e-kuaivote-kuaivote:before {
  content: "\EA35";
}

.kz-e-system_plugin-weibo:before {
  content: "\EA36";
}

.kz-e-system_plugin-title:before {
  content: "\EA37";
}

.kz-e-system_plugin-weibo-min:before {
  content: "\EA38";
}

.kz-e-system_plugin-qrcode-min:before {
  content: "\EA39";
}

.kz-e-system_plugin-nav:before {
  content: "\EA3A";
}

.kz-e-18:before {
  content: "\EA3B";
}

.kz-e-underline-new:before {
  content: "\EA3C";
}

.kz-e-add-icon:before {
  content: "\EA3D";
}

.kz-e-info-page:before {
  content: "\EA3E";
}

.kz-e-more-more:before {
  content: "\EA25";
}

.kz-e-system_plugin-pic:before {
  content: "\EA26";
}

.kz-e-system_plugin-search:before {
  content: "\EA27";
}

.kz-e-changyan-changyan:before {
  content: "\EA28";
}

.kz-e-system_plugin-button:before {
  content: "\EA29";
}

.kz-e-system_plugin-pictxt:before {
  content: "\EA2A";
}

.kz-e-system_plugin-picgroup:before {
  content: "\EA2B";
}

.kz-e-system_plugin-focus:before {
  content: "\EA2C";
}

.kz-e-system_plugin-text:before {
  content: "\EA2D";
}

.kz-e-system_plugin-video:before {
  content: "\EA2E";
}

.kz-e-system_plugin-link:before {
  content: "\EA2F";
}

.kz-e-system_plugin-forumlist:before {
  content: "\EA30";
}

.kz-e-system_plugin-shopslist:before {
  content: "\EA31";
}

.kz-e-system_plugin-commodity-list:before {
  content: "\EA18";
}

.kz-e-system_plugin-topiclist:before {
  content: "\EA19";
}

.kz-e-form-form:before {
  content: "\EA1A";
}

.kz-e-html-html:before {
  content: "\EA1B";
}

.kz-e-js-js:before {
  content: "\EA1C";
}

.kz-e-system_plugin-divider:before {
  content: "\EA1D";
}

.kz-e-system_plugin-layout2coloums:before {
  content: "\EA1E";
}

.kz-e-system_plugin-space:before {
  content: "\EA1F";
}

.kz-e-system_plugin-layout_floor:before {
  content: "\EA20";
}

.kz-e-system_plugin-guide-follow:before {
  content: "\EA21";
}

.kz-e-system_plugin-map:before {
  content: "\EA22";
}

.kz-e-system_plugin-qrcode:before {
  content: "\EA23";
}

.kz-e-system_plugin-share:before {
  content: "\EA24";
}

.kz-e-integrals:before {
  content: "\EA0B";
}

.kz-e-regal:before {
  content: "\EA0C";
}

.kz-e-popularity:before {
  content: "\EA0D";
}

.kz-e-video-post:before {
  content: "\EA0E";
}

.kz-e-img-post:before {
  content: "\EA0F";
}

.kz-e-voice-post:before {
  content: "\EA10";
}

.kz-e-newsection:before {
  content: "\EA11";
}

.kz-e-scanqr:before {
  content: "\EA12";
}

.kz-e-newarticle:before {
  content: "\EA13";
}

.kz-e-setting:before {
  content: "\EA14";
}

.kz-e-pause:before {
  content: "\EA15";
}

.kz-e-play:before {
  content: "\EA16";
}

.kz-e-radio:before {
  content: "\EA17";
}

.kz-e-radio-checked:before {
  content: "\E9FE";
}

.kz-e-expand:before {
  content: "\EA0A";
}

.kz-e-add:before {
  content: "\EA09";
}

.kz-e-close:before {
  content: "\EA08";
}

.kz-e-star:before {
  content: "\EA07";
}

.kz-e-star-o:before {
  content: "\EA06";
}

.kz-e-home2:before {
  content: "\EA05";
}

.kz-e-remind:before {
  content: "\EA04";
}

.kz-e-envelope:before {
  content: "\EA03";
}

.kz-e-angle-up:before {
  content: "\EA02";
}

.kz-e-angle-down-2:before {
  content: "\EA01";
}

.kz-e-image:before {
  content: "\E9FF";
}

.kz-e-smile:before {
  content: "\E9FD";
}

.kz-e-comment:before {
  content: "\E9FC";
}

.kz-e-scan:before {
  content: "\E9FB";
}

.kz-e-warning:before {
  content: "\E9FA";
}

.kz-e-close-2:before {
  content: "\E9F9";
}

.kz-e-mini:before {
  content: "\E9F8";
}

.kz-e-more:before {
  content: "\E9F7";
}

.kz-e-search:before {
  content: "\E9F6";
}

.kz-e-angle-down-3:before {
  content: "\E9F5";
}

.kz-e-voice:before {
  content: "\E929";
}

.kz-e-personal:before {
  content: "\E9F4";
}

.kz-e-share:before {
  content: "\E9F0";
}

.kz-e-watch:before {
  content: "\E9E2";
}

.kz-e-copy:before {
  content: "\E9E6";
}

.kz-e-list2:before {
  content: "\E9E7";
}

.kz-e-changegroup:before {
  content: "\E9E9";
}

.kz-e-rename:before {
  content: "\E9EB";
}

.kz-e-lock-o:before {
  content: "\E9EC";
}

.kz-e-lock:before {
  content: "\E9ED";
}

.kz-e-align-l:before {
  content: "\E900";
}

.kz-e-align-m:before {
  content: "\E9EF";
}

.kz-e-align-r:before {
  content: "\E9F2";
}

.kz-e-check:before {
  content: "\E9F1";
}

.kz-e-bold:before {
  content: "\E9EE";
}

.kz-e-lean:before {
  content: "\E9E0";
}

.kz-e-underline:before {
  content: "\E9DF";
}

.kz-e-link:before {
  content: "\E9D6";
}

.kz-e-plus:before {
  content: "\E9D2";
}

.kz-e-angle-down:before {
  content: "\E9D1";
}

.kz-e-angle-right:before {
  content: "\E9D0";
}

.kz-e-angle-left:before {
  content: "\E9CD";
}

.kz-e-phone:before {
  content: "\EA75";
}

.kz-e-QQ:before {
  content: "\EA76";
}

.kz-e-qq:before {
  content: "\EA66";
}

.kz-e-wisdom-tooth:before {
  content: "\EA67";
}

.kz-e-form:before {
  content: "\EA68";
}

.kz-e-shop-list .path1:before {
  content: "\EA69";
  color: rgb(26, 24, 24);
}

.kz-e-shop-list .path2:before {
  content: "\EA6A";
  margin-left: -1em;
  color: rgb(26, 24, 24);
}

.kz-e-shop-list .path3:before {
  content: "\EA6B";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.kz-e-shop-list .path4:before {
  content: "\EA6C";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.kz-e-shop-list .path5:before {
  content: "\EA6D";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.kz-e-shop-list .path6:before {
  content: "\EA6E";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.kz-e-questionnaire .path1:before {
  content: "\EA6F";
  color: rgb(0, 0, 0);
}

.kz-e-questionnaire .path2:before {
  content: "\EA70";
  margin-left: -1em;
  color: rgb(0, 2, 0);
}

.kz-e-questionnaire .path3:before {
  content: "\EA71";
  margin-left: -1em;
  color: rgb(0, 2, 0);
}

.kz-e-questionnaire .path4:before {
  content: "\EA72";
  margin-left: -1em;
  color: rgb(0, 2, 0);
}

.kz-e-questionnaire .path5:before {
  content: "\EA73";
  margin-left: -1em;
  color: rgb(0, 2, 0);
}

.kz-e-service-information-list:before {
  content: "\EA5E";
}

.kz-e-title:before {
  content: "\EA58";
}

.kz-e-big-wheel:before {
  content: "\EA59";
}

.kz-e-navigation:before {
  content: "\EA5A";
}

.kz-e-separate:before {
  content: "\EA5B";
}

.kz-e-leaving-space:before {
  content: "\EA5C";
}

.kz-e-pictures2:before {
  content: "\EA5D";
}

.kz-e-keyboard:before {
  content: "\EA54";
}

.kz-e-wechat:before {
  content: "\E9C5";
}

.kz-e-bianjiqi-T:before {
  content: "\E938";
}

.kz-e-bianjiqi-picture:before {
  content: "\E939";
}

.kz-e-bianjiqi-pictures:before {
  content: "\E93A";
}

.kz-e-bianjiqi-circle:before {
  content: "\E94C";
}

.kz-e-bianjiqi-link:before {
  content: "\E951";
}

.kz-e-bianjiqi-search:before {
  content: "\E954";
}

.kz-e-bianjiqi-vedio:before {
  content: "\E95B";
}

.kz-e-bianjiqi-chart:before {
  content: "\E95C";
}

.kz-e-bianjiqi-html:before {
  content: "\E95D";
}

.kz-e-bianjiqi-js:before {
  content: "\E95E";
}

.kz-e-bianjiqi-spacing:before {
  content: "\E95F";
}

.kz-e-bianjiqi-cut:before {
  content: "\E960";
}

.kz-e-bianjiqi-double:before {
  content: "\E961";
}

.kz-e-bianjiqi-yinying:before {
  content: "\E962";
}

.kz-e-bianjiqi-position:before {
  content: "\E963";
}

.kz-e-bianjiqi-saoma:before {
  content: "\E964";
}

.kz-e-bianjiqi-share:before {
  content: "\E965";
}

.kz-e-bianjiqi-wechat:before {
  content: "\E966";
}

.kz-e-bianjiqi-chang:before {
  content: "\E967";
}

.kz-e-bianjiqi-heart:before {
  content: "\E968";
}

.kz-e-bianjiqi-chatlist:before {
  content: "\E969";
}

.kz-e-bianjiqi-chat:before {
  content: "\E96A";
}

.kz-e-bianjiqi-shop:before {
  content: "\E96B";
}

.kz-e-bianjiqi-shoplist:before {
  content: "\E96C";
}

.kz-e-bianjiqi-page:before {
  content: "\E96D";
}

.kz-e-bianjiqi-ticket:before {
  content: "\E96E";
}

.kz-e-bianjiqi-link-2:before {
  content: "\E96F";
}

.kz-e-bianjiqi-paper:before {
  content: "\E970";
}

.kz-e-bianjiqi-check-1:before {
  content: "\E971";
}

.kz-e-bianjiqi-check-2:before {
  content: "\E972";
}

.kz-e-bianjiqi-article:before {
  content: "\E973";
}

.kz-e-bianjiqi-check-3:before {
  content: "\E974";
}

.kz-e-bianjiqi-more:before {
  content: "\E975";
}

.kz-e-bianjiqi-add:before {
  content: "\E976";
}

.kz-e-bianjiqi-:before {
  content: "\E980";
}

.kz-e-bianjiqi-ticket-2:before {
  content: "\E987";
}

.kz-e-bianjiqi-ear:before {
  content: "\E9AA";
}

.kz-e-bianjiqi-weibo:before {
  content: "\E9AB";
}

.kz-e-bianjiqi-up:before {
  content: "\E9AC";
}

.kz-e-add-big:before {
  content: "\E9AD";
}

.kz-e-picture-big:before {
  content: "\E9AE";
}

.kz-e-download:before {
  content: "\E9E4";
}

.kz-e-time-01:before {
  content: "\EA5F";
}

.kz-e-enterprise:before {
  content: "\E9F3";
}

.kz-e-import:before {
  content: "\EA50";
}

.kz-e-QR-code:before {
  content: "\EA51";
}

.kz-e-reading-number:before {
  content: "\E9C7";
}

.kz-e-text:before {
  content: "\E9CE";
}

.kz-e-image-text:before {
  content: "\E9D4";
}

.kz-e-image3:before {
  content: "\E9E8";
}

.kz-e-arrow-up:before {
  content: "\E911";
}

.kz-e-arrow-down2:before {
  content: "\E9B1";
}

.kz-e-*20-02:before {
  content: "\E9C4";
}

.kz-e-question-new:before {
  content: "\EA53";
}

.kz-e-tips:before {
  content: "\E912";
}

.kz-e-check--new-:before {
  content: "\E913";
}

.kz-e-add-new--1:before {
  content: "\E914";
}

.kz-e-question-new-1:before {
  content: "\E915";
}

.kz-e-check--new--1:before {
  content: "\E916";
}

.kz-e-user:before {
  content: "\E917";
}

.kz-e-bold-new-:before {
  content: "\E918";
}

.kz-e-italic:before {
  content: "\E919";
}

.kz-e-underline-new-1:before {
  content: "\E91A";
}

.kz-e-type-new:before {
  content: "\E91B";
}

.kz-e-type-new-1:before {
  content: "\E91C";
}

.kz-e-type-new-2:before {
  content: "\E91D";
}

.kz-e-wenzhang-up:before {
  content: "\E91E";
}

.kz-e-wenzhang-down:before {
  content: "\E91F";
}

.kz-e-wenzhang-suojin:before {
  content: "\E920";
}

.kz-e-wenzhang-spacing:before {
  content: "\E921";
}

.kz-e-wenzhang-right:before {
  content: "\E922";
}

.kz-e-wenzhang-left:before {
  content: "\E923";
}

.kz-e-wenzhang-center:before {
  content: "\E924";
}

.kz-e-wenzhang-brush:before {
  content: "\E925";
}

.kz-e-wenzhang-eraser:before {
  content: "\E926";
}

.kz-e-wenzhang-chart:before {
  content: "\E927";
}

.kz-e-wenzhang-I:before {
  content: "\E928";
}

.kz-e-wenzhang-I-2:before {
  content: "\E92A";
}

.kz-e-wenzhang-list:before {
  content: "\E92B";
}

.kz-e-wenzhang-list-2:before {
  content: "\E92C";
}

.kz-e-pictures:before {
  content: "\E92D";
}

.kz-e-wenzhang-pictures-2:before {
  content: "\E92E";
}

.kz-e-wenzhang-link:before {
  content: "\E92F";
}

.kz-e-wenzhang-link2:before {
  content: "\E930";
}

.kz-e-wenzhang-smile:before {
  content: "\E931";
}

.kz-e-wenzhang-vedio:before {
  content: "\E932";
}

.kz-e-wenzhang-shop:before {
  content: "\E933";
}

.kz-e-wenzhang-music:before {
  content: "\E934";
}

.kz-e-wenzhang-return:before {
  content: "\E935";
}

.kz-e-wenzhang-return2:before {
  content: "\E936";
}

.kz-e-wenzhang-html:before {
  content: "\E937";
}

.kz-e-user-not-logged:before {
  content: "\E9D8";
}

.kz-e-add-m:before {
  content: "\E9C3";
}

.kz-e-set-border-radius:before {
  content: "\E979";
}

.kz-e-set-padding:before {
  content: "\E977";
}

.kz-e-set-margin:before {
  content: "\E978";
}

.kz-e-lock-radius:before {
  content: "\E955";
}

.kz-e-unlock-radius:before {
  content: "\E956";
}

.kz-e-check2:before {
  content: "\EA74";
}

.kz-e-warn2:before {
  content: "\E902";
}

.kz-e-up:before {
  content: "\E903";
}

.kz-e-play-2:before {
  content: "\E904";
}

.kz-e-delete:before {
  content: "\E905";
}

.kz-e-down-3:before {
  content: "\E906";
}

.kz-e-edit-3:before {
  content: "\E907";
}

.kz-e-close-3:before {
  content: "\E908";
}

.kz-e-close-4:before {
  content: "\E909";
}

.kz-e-next-3:before {
  content: "\E90A";
}

.kz-e-time-3:before {
  content: "\E90B";
}

.kz-e-down:before {
  content: "\E90C";
}

.kz-e-rili:before {
  content: "\E90D";
}

.kz-e-download-3:before {
  content: "\E90E";
}

.kz-e-*14-14:before {
  content: "\E90F";
}

.kz-e-arrow-down:before {
  content: "\E9CA";
}

.kz-e-calendar:before {
  content: "\E9CB";
}

.kz-e-close2:before {
  content: "\E9CC";
}

.kz-e-download-solid:before {
  content: "\E9DE";
}

.kz-e-edit:before {
  content: "\E9CF";
}

.kz-e-error-solid:before {
  content: "\E9E1";
}

.kz-e-foward-right:before {
  content: "\E9E3";
}

.kz-e-time:before {
  content: "\E9D3";
}

.kz-e-upload-solid:before {
  content: "\E9D5";
}

.kz-e-add-new2:before {
  content: "\E97A";
}

.kz-e-close-new:before {
  content: "\E97B";
}

.kz-e-help:before {
  content: "\E97C";
}

.kz-e-component:before {
  content: "\E93B";
}

.kz-e-pages:before {
  content: "\E93C";
}

.kz-e-theme:before {
  content: "\E93D";
}

.kz-e-redo:before {
  content: "\E93E";
}

.kz-e-undo:before {
  content: "\E93F";
}

.kz-e-copy-new:before {
  content: "\E940";
}

.kz-e-paste:before {
  content: "\E941";
}

.kz-e-pages-component:before {
  content: "\E942";
}

.kz-e-setting-new:before {
  content: "\E943";
}

.kz-e-share-new:before {
  content: "\E944";
}

.kz-e-ruler:before {
  content: "\E945";
}

.kz-e-refresh:before {
  content: "\E946";
}

.kz-e-save:before {
  content: "\E947";
}

.kz-e-preview:before {
  content: "\E948";
}

.kz-e-update-pages:before {
  content: "\E949";
}

.kz-e-create-group:before {
  content: "\E957";
}

.kz-e-create-page:before {
  content: "\E958";
}

.kz-e-search-new:before {
  content: "\E959";
}

.kz-e-page:before {
  content: "\EA60";
}

.kz-e-copy2:before {
  content: "\EA61";
}

.kz-e-delete2:before {
  content: "\EA62";
}

.kz-e-file:before {
  content: "\EA63";
}

.kz-e-close-eye:before {
  content: "\EA64";
}

.kz-e-open-eye:before {
  content: "\EA65";
}

.kz-e-sort2:before {
  content: "\EA55";
}

.kz-e-list:before {
  content: "\EA56";
}

.kz-e-sort22:before {
  content: "\EA57";
}

.kz-e-loading-button:before {
  content: "\E910";
}

.kz-e-nav-pull-down:before {
  content: "\EA52";
}

.kz-e-collection:before {
  content: "\E9EA";
}

.kz-e-pull-down:before {
  content: "\EA4C";
}

.kz-e-forward:before {
  content: "\EA4E";
}

.kz-e-home:before {
  content: "\E901";
}

.kz-e-que:before {
  content: "\E9E5";
}

.kz-e-success-solid:before {
  content: "\E9D7";
}

.kz-e-unwatch:before {
  content: "\E9B9";
}

.kz-e-upgrade:before {
  content: "\E9BA";
}

.kz-e-liebiao:before {
  content: "\E9BB";
}

.kz-e-shaixuan:before {
  content: "\E9BC";
}

.kz-e-kz-99-plugin:before {
  content: "\E9BD";
}

.kz-e-system_plugin-layout2coloums-min:before {
  content: "\E9B0";
}

.kz-e-page_plugin-down:before {
  content: "\E9A6";
}

.kz-e-page_plugin-up:before {
  content: "\E9A7";
}

.kz-e-page_plugin-right:before {
  content: "\E9A8";
}

.kz-e-page_plugin-left:before {
  content: "\E9A9";
}

.kz-e-system_plugin-button-min:before {
  content: "\E986";
}

.kz-e-system_plugin-title-min:before {
  content: "\E991";
}

.kz-e-html-html-min:before {
  content: "\E992";
}

.kz-e-js-js-min:before {
  content: "\E994";
}

.kz-e-form-form-min:before {
  content: "\E9A5";
}

.kz-e-copy-page:before {
  content: "\E94A";
}

.kz-e-del-new:before {
  content: "\E97E";
}

.kz-e-system_plugin-link-min:before {
  content: "\E97D";
}

.kz-e-drop-down:before {
  content: "\E97F";
}

.kz-e-drop-up:before {
  content: "\E99C";
}

.kz-e-drop-right:before {
  content: "\E99F";
}

.kz-e-drop-left:before {
  content: "\E9A0";
}

.kz-e-system_plugin-search-min:before {
  content: "\E981";
}

.kz-e-changyan-changyan-min:before {
  content: "\E982";
}

.kz-e-system_plugin-pictxt-min:before {
  content: "\E983";
}

.kz-e-system_plugin-picgroup-min:before {
  content: "\E984";
}

.kz-e-system_plugin-pic-min:before {
  content: "\E985";
}

.kz-e-system_plugin-nav-min:before {
  content: "\E988";
}

.kz-e-system_plugin-focus-min:before {
  content: "\E989";
}

.kz-e-system_plugin-text-min:before {
  content: "\E98A";
}

.kz-e-edit-new:before {
  content: "\E98B";
}

.kz-e-system_plugin-guide-follow-min:before {
  content: "\E98C";
}

.kz-e-system_plugin-forumlist-min:before {
  content: "\E98D";
}

.kz-e-system_plugin-shopslist-min:before {
  content: "\E98E";
}

.kz-e-system_plugin-commodity-list-min:before {
  content: "\E98F";
}

.kz-e-system_plugin-topiclist-min:before {
  content: "\E990";
}

.kz-e-system_plugin-video-min:before {
  content: "\E993";
}

.kz-e-system_plugin-divider-min:before {
  content: "\E995";
}

.kz-e-system_plugin-layout_floor-min:before {
  content: "\E996";
}

.kz-e-system_plugin-space-min:before {
  content: "\E997";
}

.kz-e-system_plugin-map-min:before {
  content: "\E998";
}

.kz-e-system_plugin-share-min:before {
  content: "\E999";
}

.kz-e-info-left:before {
  content: "\E99A";
}

.kz-e-info-right:before {
  content: "\E99B";
}

.kz-e-more-new:before {
  content: "\E9A1";
}

.kz-e-pagination-up:before {
  content: "\E9A2";
}

.kz-e-pagination-down:before {
  content: "\E9A3";
}

.kz-e-pagination-previous:before {
  content: "\E99D";
}

.kz-e-pagination-next:before {
  content: "\E99E";
}

.kz-e-16:before {
  content: "\E9A4";
}

.kz-e-lock-ratio:before {
  content: "\E94B";
}

.kz-e-left-justified:before {
  content: "\E94D";
}

.kz-e-center-justified:before {
  content: "\E94E";
}

.kz-e-right-justified:before {
  content: "\E94F";
}

.kz-e-bold-new:before {
  content: "\E950";
}

.kz-e-lean-new:before {
  content: "\E952";
}

.kz-e-add-new:before {
  content: "\E953";
}

.kz-e-info-group:before {
  content: "\E95A";
}
